<template>
  <div style="min-height:'300px'">
    <div v-if="participated" class="pa-3">
      <div class="d-flex justify-end" @click="fetch()">
        <v-btn>Reload</v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="participated.data"
        class="elevation-0 "
      >
        <template v-slot:[`item.date`]="{ item }">
          <div v-if="item.relationships[0].agenda.data">
            {{
              $date(item.relationships[0].agenda.data.start_date).format(
                "DD MMMM YYYY",
              )
            }}
          </div>
        </template>
        <template v-slot:[`item.pay`]="{ item }">
          <div>
            <v-btn
              rounded
              depressed
              color="green"
              outlined
              v-if="item.relationships[1].payment.data"
            >
              Paid
            </v-btn>
            <div v-if="!item.relationships[1].payment.data">
              <v-tooltip top v-if="item.relationships[0].agenda.data">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    depressed
                    color="green"
                    dark
                    v-if="
                      item.relationships[0].agenda.data.tipe_agenda ==
                        'berbayar'
                    "
                    @click="rejoinEvt(item)"
                  >
                    PAY
                  </v-btn>
                </template>
                <span>Klk untuk mebayar event</span>
              </v-tooltip>
              <div v-if="item.relationships[0].agenda.data">
                <v-btn
                  rounded
                  depressed
                  color="green"
                  dark
                  v-if="
                    item.relationships[0].agenda.data.tipe_agenda == 'gratis'
                  "
                  @click="rejoinEvt(item)"
                >
                  Free
                </v-btn>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div v-if="item.relationships[0].agenda.data">
            <div
              v-if="item.relationships[0].agenda.data.tipe_agenda == 'berbayar'"
            >
              <v-btn
                rounded
                depressed
                :color="
                  item.relationships[1].payment.data.status_pembayaran ==
                  'waiting'
                    ? 'orange'
                    : item.relationships[1].payment.data.status_pembayaran ==
                      'accepted'
                    ? 'green'
                    : 'red'
                "
                dark
                v-if="item.relationships[1].payment.data"
              >
                {{ item.relationships[1].payment.data.status_pembayaran }}
              </v-btn>
              <v-btn
                rounded
                depressed
                color="grey
            "
                dark
                v-if="!item.relationships[1].payment.data"
              >
                UNPAID
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="pa-2">
      <v-overlay :absolute="true" :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="dialogPay" max-width="500">
        <v-card>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-btn fab x- small depressed @click="dialogPay = false"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <div class="d-flex justify-center align-center">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card class="pa-3 my-3" outlined v-if="selectedEvt">
                    <h3>Bayar Ke</h3>
                    <v-row>
                      <v-col cols="4">Nomor Rekening</v-col>
                      <v-col cols="8">: {{ selectedEvt.agenda.no_rek }}</v-col>
                      <v-col cols="4">Bank</v-col>
                      <v-col cols="8"
                        >:
                        {{
                          selectedEvt.agenda.nama_bank
                            ? selectedEvt.agenda.nama_bank
                            : "-"
                        }}</v-col
                      >
                      <v-col cols="4">Atas Nama</v-col>
                      <v-col cols="8"
                        >:
                        {{
                          selectedEvt.agenda.nama_rek
                            ? selectedEvt.agenda.nama_rek
                            : "-"
                        }}</v-col
                      >
                    </v-row>
                  </v-card>
                  <v-file-input
                    label="Upload Bukti Pembayaran"
                    v-model="file"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" outlined rounded @click="dialogPay = false"
              >cancel</v-btn
            >
            <v-btn color="blue" outlined rounded v-if="!loading" @click="pay"
              >Submit</v-btn
            >
            <v-btn color="blue" outlined rounded v-if="loading">Loading</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "listPrticipatedPsy",
  computed: {
    ...mapState({
      participated: (state) => state.agenda.participatedEvt,
      env: (state) => state.API_URL,
      id: (state) => state.id,
    }),
  },
  data() {
    return {
      dialogPay: false,
      dialogView: false,
      idParticipant: null,
      loading: false,
      file: null,
      page: 1,
      perPage: 10,
      headers: [
        { text: "ID", value: "id", sortable: false },
        {
          text: "EVENT NAME",
          value: "relationships[0].agenda.data.judul",
          sortable: false,
        },
        {
          text: "DATE",
          value: "date",
          sortable: false,
        },
        { text: "PAY", value: "pay", align: "start", sortable: false },
        { text: "STATUS", value: "status", align: "start", sortable: false },
      ],
      selectedEvt: null,
      loader: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loader = true;
      this.$store
        .dispatch("agenda/participatedEvtPsy")
        .then((data) => {
          console.log(data);
          this.loader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rejoinEvt(item) {
      item.relationships.forEach((child) => {
        if (child.agenda) {
          item["agenda"] = child.agenda.data;
        }
      });
      this.selectedEvt = item;
      this.dialogPay = true;
    },
    pay() {
      this.loading = true;
      let body = new FormData();
      body.append("agenda_participant_id", this.selectedEvt.id);
      body.append("metode_pembayaran", "transfer");
      body.append("no_rekening", this.selectedEvt.agenda.no_rek);
      body.append("objBuktiPembayaran", this.file);
      this.$store
        .dispatch("agenda/payEvt", body)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.$emit("close");
            Swal.fire({
              icon: "success",
              title: data.message,
            });
            this.fetch();
            this.dialogPay = false;
          } else {
            console.log(data);
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
._wave {
  /* position: relative; */
  overflow: initial;
  background: url("../../assets/img/bg-wave-right.svg");
  background-position: center;
  background-size: cover;
}

.max {
  height: max-content;
}

.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
.temp-info {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 300px;
}
.label-harga {
  position: relative;
  margin-bottom: -30px;
  z-index: 2;
}
</style>
