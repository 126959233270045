<template>
  <div>
    <div v-if="agenda" class="pa-3">
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
          v-for="item in agenda.data"
          :key="item.id"
        >
          <div class="d-flex justify-end">
            <v-chip
              class="label-harga"
              color="green"
              dark
              label
              text-color="white"
            >
              <v-icon left> mdi-label </v-icon>
              <p class="ma-0" v-if="item.tipe_agenda == 'gratis'">Gratis</p>
              <p class="ma-0" v-if="item.tipe_agenda == 'berbayar'">
                Rp. {{ item.biaya }}
              </p>
            </v-chip>
          </div>
          <v-card
            class="radius-card artcle_card__ ma-2 pa-3 d-flex align-end"
            height="400px"
            :id="`evt-${item.id}`"
          >
            <div class="pa-3 temp-info">
              <h4 v-snip="1">{{ item.judul }}</h4>
              <p class="ma-0 grey--text">
                by <b>{{ item.pemateri }}</b>
              </p>
              <div class="d-flex">
                <v-card
                  class="px-3 py-2 d-flex align-center"
                  style="width: max-content"
                  color="#EEEEEE"
                  flat
                >
                  <v-icon class="mr-2 mt-2">mdi-calendar</v-icon>
                  {{ $date(item.start_date).format("DD MMM, YYYY") }}
                </v-card>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text class="px-0 ml-2">
                      <v-icon>mdi-account-multiple</v-icon>
                      {{ item.kuota - item.available.sisa }}/{{ item.kuota }}
                    </v-btn>
                  </template>
                  <span>Kuota</span>
                </v-tooltip>
                <!-- <div>
                  <small>kuota {{ item.kuota }}</small> <br>
                  <small>sisa {{ item.available.sisa }}</small>
                </div> -->
              </div>
              <v-card outlined class="mt-2 pa-2" height="130px">
                <div class="d-flex justify-center">
                  <v-icon>mdi-map-marker-radius</v-icon>
                </div>
                <p class="ma-0 text-center">{{ item.lokasi }}</p>
              </v-card>
              <div class="pa-2">
                <v-btn
                  class="mr-1"
                  dark
                  color="blue"
                  depressed
                  :to="`/admin/agenda-view/${item.id}`"
                >
                  Lihat</v-btn
                >
                <v-btn
                  v-if="item.available.status"
                  dark
                  color="orange"
                  depressed
                  @click="joinEvt(item)"
                  >Join</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-center align-center flex-column py-5"
        v-if="!agenda.data.length"
      >
        <img src="../../assets/img/404.svg" height="150px" alt="" />
        <h3 class="text-center">No Event Available</h3>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "listAgendaPsy",
  computed: {
    ...mapState({
      agenda: state => state.agenda.agenda,
      env: state => state.API_URL,
      id: state => state.id
    })
    // ...mapState({
    //   agenda: (state) => state.agenda.agenda,
    //   role: (state) => state.role,
    // }),
  },
  data() {
    return {
      dialogPay: false,
      dialogView: false,
      page: 1,
      perPage: 10,
      dialogJoin: false,
      dataEvt: null,
      idParticipant: null
    };
  },
  mounted() {
    this.fetchAgenda();
  },
  methods: {
    fetchAgenda() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search
      };
      this.loader = true;
      this.$store
        .dispatch("agenda/listAgenda", data)
        .then(data => {
          data.data.data.forEach(el => {
            document.getElementById(
              `evt-${el.id}`
            ).style.background = `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('${this.env}/agenda/${el.poster}')`;
          });
          this.loader = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    joinEvt(item) {
      let data = {
        id_evt: item.id,
        id_user: this.id
      };
      this.$store.dispatch("agenda/joinEvtPsy", data).then(data => {
        Swal.fire({
          title: data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          position: "top",
          toast: true
        }).then(() => {
          this.$emit("switchTab");
        });
      });
    }
  }
};
</script>

<style scoped>
._wave {
  /* position: relative; */
  overflow: initial;
  background: url("../../assets/img/bg-wave-right.svg");
  background-position: center;
  background-size: cover;
}

.max {
  height: max-content;
}

.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
.temp-info {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 300px;
}
.label-harga {
  position: relative;
  margin-bottom: -30px;
  z-index: 2;
}
</style>
