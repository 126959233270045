<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card>
        <v-tabs
          v-model="tab"
          class="mb-5 rounded"
          background-color="purple lighten-5"
          color="purple"
          grow
        >
          <v-tab href="#tab-1"
            ><v-icon>mdi-book</v-icon><b>List Event</b></v-tab
          >
          <v-tab href="#tab-2"
            ><v-icon>mdi-book-multiple</v-icon><b>My Event</b></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: none">
          <v-tab-item value="tab-1">
            <ListEventPsy @switchTab="switchTab" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <ParticipatedEventPsy />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListEventPsy from "../../components/Psycholog/listEventPsy.vue";
import ParticipatedEventPsy from "../../components/Psycholog/participatedEventPsy.vue";

export default {
  components: { ListEventPsy, ParticipatedEventPsy },
  name: "evenypxy",
  data() {
    return {
      dialogFeed: false,
      tab: "tab-1"
    };
  },
  computed: {
    ...mapState({
      env: state => state.API_URL
    })
  },
  mounted() {},
  methods: {
    switchTab() {
      this.tab = "tab-2";
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._10w {
  width: 40%;
  padding-right: 20px;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
</style>
